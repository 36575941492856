import React from "react";
import Layout from "../layouts";


const NotFound = () => {
  return <Layout title="Page Not Found">
  </Layout>
}

export default NotFound;
